import axios from './configuredAxios'

const services = {
  /**
   * @description checkIp
   */
  checkIp: () => axios.get('/account/check_ip/'),

  /**
   * @description trackUser
   */
  trackUser: () => axios.get('/statistics/track-user/'),

  /**
   * @description websiteLogs
   */
  websiteLogs: (data) => axios.post('/website/logs/', data),

  /**
   * @description getRestrictedCountries
   */
  getRestrictedCountries: () => axios.get('/user/check_risk_country/'),
}

export default services
